import React from 'react';
import './App.scss';
import AppContainer from './AppContainer'

function App() {
  return (
    <div className="App">
      <h1>DAB OR SNEEZE?</h1>

      <AppContainer />

      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="site-info">
          <p>A <a href="http://keanankoppenhaver.com">Koppenhaver</a> and <a href="https://www.linkedin.com/in/zak-tracy-7a696aa/">Tracy</a> production.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
