import React from 'react';

const contentful = require('contentful');

class AppContainer extends React.Component {
	constructor(props) {
	    super(props);
	    
	    this.state = {
	    	guess: '',
	    	posts: [],
	    	currentPost: [],
	    	previouslyGuessed: [],
	    	visibleContainer: 'before',
	    	successMessage: '',
	    	firstLoad: true
	    }
	}

	componentDidMount() {
		// TODO: Check if we're at a non-root URL and need to specify that particular post to be included
		// Pass it into the updatePosts function
		this.updatePosts();	
    }

    refreshPage() {
    	window.location.reload();
    }

	handleClick(e){
		if( ( ! this.state.currentPost.fields.dabsneeze && e.target.dataset.answer === 'sneeze' ) || ( this.state.currentPost.fields.dabsneeze && e.target.dataset.answer === 'dab' ) ) {
			var message = `Right on! It's a ${e.target.dataset.answer}.`;
		}
		else {
			var answer = 'dab' === e.target.dataset.answer ? 'sneeze' : 'dab';

			message = `Nah, bro. It's a ${answer}.`;
		}

		// Check if the guess was correct
		this.setState({
	      guess: e.target.dataset.answer,
	      visibleContainer: 'after',
	      successMessage: message
	    });
	}

	shuffle(a) {
		var j, x, i;
		for (i = a.length - 1; i > 0; i--) {
		    j = Math.floor(Math.random() * (i + 1));
		    x = a[i];
		    a[i] = a[j];
		    a[j] = x;
		}
		return a;
	}

	updatePosts(){
		// TODO: Get any passed in ID and make sure that ID comes back in the initial query

		const client = contentful.createClient({
		  space: '3xe0vrygfcra',
		  environment: 'master',
		  accessToken: 'ktlvRbVmlsdPM5XYHxh9sNi8YyUCM5r78sT7vVEQLEE'
		});

	    client.getEntries({
  			limit: 5,
  			'sys.id[nin]': this.state.previouslyGuessed.join(','),
		})
		.then((response) => {
			this.shuffle( response.items );

			this.setState({
	      		posts: response
	    	});

	    	// This can't happen any time other than initial page load
	    	// because otherwise it'll skip over important stuff
	    	if( this.state.firstLoad ) {
	    		// TODO: Make sure any passed in ID is moved to the top of the array before nextPost is called
	    		// so it's the first post the user sees

	    		this.nextPost();
	    		
	    		this.setState({
	    			firstLoad: false
	    		});
	    	}
	    	
		})
		.catch(console.error);
	}

	nextPost(){
		var postsArray = this.state.posts;
		var nextPost = postsArray.items.pop();

		console.log('posts array');
		console.log(nextPost);

		if( 'undefined' === typeof nextPost ) {
			this.setState({
				visibleContainer: 'empty'
			});

			return;
		}

		// Assign the next post in posts to currentPosts
		this.setState({
	      currentPost: nextPost,
	      guess: '',
	      visibleContainer: 'before',
	      successMessage: ''
	    });

	    this.setState({
	      posts: postsArray
	    });

		var previouslyGuessed = this.state.previouslyGuessed;
		var oldPostID = nextPost.sys.id;

		previouslyGuessed.push(oldPostID);

		this.setState({
	      previouslyGuessed: previouslyGuessed
	    });

	    // TODO: Change the URL and add the ID of this post

		if( postsArray.items.length < 1 ) {
			this.updatePosts();
		}
	}

	render(){
		const thumbnailSrc = () => {
			if( this.state.currentPost.fields ) {
				return this.state.currentPost.fields.thumbnail.fields.file.url;
			}
			else {
				return '';
			}
		}

		const gifSrc = () => {
			if( this.state.currentPost.fields ) {
				return this.state.currentPost.fields.fullMotionGif.fields.file.url;
			}
			else {
				return '';
			}
		}

		return(
			<div>
				{this.state.visibleContainer === 'before' &&
					<div className="before">
						<img src={thumbnailSrc()} alt=""/>
						
				    	<br/>
			
				    	<button className="answer" data-answer="dab" onClick={this.handleClick.bind(this)}>Dab</button>
				    	<button className="answer" data-answer="sneeze" onClick={this.handleClick.bind(this)}>Sneeze</button>
					</div>
				}
				{this.state.visibleContainer === 'after' &&
					<div className="after">
						<img src={gifSrc()} alt=""/>

						<p className="result">{this.state.successMessage}</p>

						<button onClick={this.nextPost.bind(this)}>Another one!</button>
					</div>
				}
				{this.state.visibleContainer === 'empty' &&
					<div className="empty">
						<p className="result">You've seen them all!</p>

						<p className="result">Is there a GIF you'd like to see here?</p>

						<p className="result">Send an email with a link to <a href="mailto:keanan@alphaparticle.com">keanan@alphaparticle.com</a>.</p>

						<button onClick={this.refreshPage.bind(this)}>Play again!</button>
					</div>
				}
			</div>
	    );
	}
}

export default AppContainer;